import { keycloakService } from '@ga/login';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { start } from 'single-spa';

import './environment';
import { registerApplications } from './registerApplications';

import 'antd/dist/reset.css';
import 'shared/styles/common.scss';

declare global {
  interface Window {
    ENV_VARIABLES: {
      SENTRY_DSN: string;
      ENVIRONMENT: string;
    };
  }
}

const sentrySupportedEnvs = ['pre-prod', 'prod'];
if (
  sentrySupportedEnvs.includes(window.ENV_VARIABLES.ENVIRONMENT) &&
  window.ENV_VARIABLES.SENTRY_DSN
) {
  Sentry.init({
    dsn: window.ENV_VARIABLES.SENTRY_DSN,
    environment: window.ENV_VARIABLES.ENVIRONMENT,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/gis-mbo-stage.slotegrator\.com.*$/,
      /^https:\/\/mbo.k8platform\.xyz.*$/,
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const checkAuthAndStart = async () => {
  // Initialize Micro-Frontends
  registerApplications();

  // Init Keycloak provider and login automatically
  await keycloakService.init();

  // Run Single SPA
  start({ urlRerouteOnly: true });
};

checkAuthAndStart();
